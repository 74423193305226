import React from "react";
import styled from "styled-components";
import {
  Button,
  CardDescription,
  CardTitle,
  Modal,
} from "atoms";
import { Show } from "types";
import { useState } from "react";
import BecomeMember from "apps/PublicPage/components/BecomeMember";

const SChatPaywall = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px;
`;
const PaywallInner = styled.div``;
const ModalInner = styled.div`
  padding: 0 0 20px;
`;
type Props = {
  show: Show;
  refetchShowQuery: Function
};

const ChatPaywall = (props: Props) => {
  const { show, refetchShowQuery } = props;
  const [joinVisible, setJoinVisible] = useState(false);

  const handleJoin = () => {
    setJoinVisible(true);
  };
  return (
    <SChatPaywall>
      <PaywallInner>
        <CardTitle className="mb-3">Subscribe!</CardTitle>
        <CardDescription className="mb-3" grey>
          My chat room is for subscribers only. <br />
          Please subscribe to get access!
        </CardDescription>
        <Button block onClick={handleJoin}>
          subscribe
        </Button>
      </PaywallInner>
      <Modal
        open={joinVisible}
        onCancel={() => setJoinVisible(false)}
        simple
        width={380}
        title="Subscribe"
      >
        <ModalInner>
          <BecomeMember show={show} refetchShowQuery={refetchShowQuery} />
        </ModalInner>
      </Modal>
    </SChatPaywall>
  );
};

export default ChatPaywall;
